var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = Event;

function Event(family) {}

Event.prototype.initEvent = function _Event_initEvent(type, bubbles, cancelable) {
  (this || _global).type = type;
  (this || _global).bubbles = bubbles;
  (this || _global).cancelable = cancelable;
};

Event.prototype.preventDefault = function _Event_preventDefault() {};

export default exports;