import _domElement from "./dom-element.js";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var DOMElement = _domElement;
exports = DocumentFragment;

function DocumentFragment(owner) {
  if (!((this || _global) instanceof DocumentFragment)) {
    return new DocumentFragment();
  }

  (this || _global).childNodes = [];
  (this || _global).parentNode = null;
  (this || _global).ownerDocument = owner || null;
}

DocumentFragment.prototype.type = "DocumentFragment";
DocumentFragment.prototype.nodeType = 11;
DocumentFragment.prototype.nodeName = "#document-fragment";
DocumentFragment.prototype.appendChild = DOMElement.prototype.appendChild;
DocumentFragment.prototype.replaceChild = DOMElement.prototype.replaceChild;
DocumentFragment.prototype.removeChild = DOMElement.prototype.removeChild;

DocumentFragment.prototype.toString = function _DocumentFragment_toString() {
  return (this || _global).childNodes.map(function (node) {
    return String(node);
  }).join("");
};

export default exports;