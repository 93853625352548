var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = Comment;

function Comment(data, owner) {
  if (!((this || _global) instanceof Comment)) {
    return new Comment(data, owner);
  }

  (this || _global).data = data;
  (this || _global).nodeValue = data;
  (this || _global).length = data.length;
  (this || _global).ownerDocument = owner || null;
}

Comment.prototype.nodeType = 8;
Comment.prototype.nodeName = "#comment";

Comment.prototype.toString = function _Comment_toString() {
  return "[object Comment]";
};

export default exports;