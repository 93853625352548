var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = addEventListener;

function addEventListener(type, listener) {
  var elem = this || _global;

  if (!elem.listeners) {
    elem.listeners = {};
  }

  if (!elem.listeners[type]) {
    elem.listeners[type] = [];
  }

  if (elem.listeners[type].indexOf(listener) === -1) {
    elem.listeners[type].push(listener);
  }
}

export default exports;