import _domWalk from "dom-walk";
import _dispatchEvent from "./event/dispatch-event.js";
import _addEventListener from "./event/add-event-listener.js";
import _removeEventListener from "./event/remove-event-listener.js";
import _serialize from "./serialize.js";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var domWalk = _domWalk;
var dispatchEvent = _dispatchEvent;
var addEventListener = _addEventListener;
var removeEventListener = _removeEventListener;
var serializeNode = _serialize;
var htmlns = "http://www.w3.org/1999/xhtml";
exports = DOMElement;

function DOMElement(tagName, owner, namespace) {
  if (!((this || _global) instanceof DOMElement)) {
    return new DOMElement(tagName);
  }

  var ns = namespace === undefined ? htmlns : namespace || null;
  (this || _global).tagName = ns === htmlns ? String(tagName).toUpperCase() : tagName;
  (this || _global).nodeName = (this || _global).tagName;
  (this || _global).className = "";
  (this || _global).dataset = {};
  (this || _global).childNodes = [];
  (this || _global).parentNode = null;
  (this || _global).style = {};
  (this || _global).ownerDocument = owner || null;
  (this || _global).namespaceURI = ns;
  (this || _global)._attributes = {};

  if ((this || _global).tagName === "INPUT") {
    (this || _global).type = "text";
  }
}

DOMElement.prototype.type = "DOMElement";
DOMElement.prototype.nodeType = 1;

DOMElement.prototype.appendChild = function _Element_appendChild(child) {
  if (child.parentNode) {
    child.parentNode.removeChild(child);
  }

  (this || _global).childNodes.push(child);

  child.parentNode = this || _global;
  return child;
};

DOMElement.prototype.replaceChild = function _Element_replaceChild(elem, needle) {
  // TODO: Throw NotFoundError if needle.parentNode !== this
  if (elem.parentNode) {
    elem.parentNode.removeChild(elem);
  }

  var index = (this || _global).childNodes.indexOf(needle);

  needle.parentNode = null;
  (this || _global).childNodes[index] = elem;
  elem.parentNode = this || _global;
  return needle;
};

DOMElement.prototype.removeChild = function _Element_removeChild(elem) {
  // TODO: Throw NotFoundError if elem.parentNode !== this
  var index = (this || _global).childNodes.indexOf(elem);

  (this || _global).childNodes.splice(index, 1);

  elem.parentNode = null;
  return elem;
};

DOMElement.prototype.insertBefore = function _Element_insertBefore(elem, needle) {
  // TODO: Throw NotFoundError if referenceElement is a dom node
  // and parentNode !== this
  if (elem.parentNode) {
    elem.parentNode.removeChild(elem);
  }

  var index = needle === null || needle === undefined ? -1 : (this || _global).childNodes.indexOf(needle);

  if (index > -1) {
    (this || _global).childNodes.splice(index, 0, elem);
  } else {
    (this || _global).childNodes.push(elem);
  }

  elem.parentNode = this || _global;
  return elem;
};

DOMElement.prototype.setAttributeNS = function _Element_setAttributeNS(namespace, name, value) {
  var prefix = null;
  var localName = name;
  var colonPosition = name.indexOf(":");

  if (colonPosition > -1) {
    prefix = name.substr(0, colonPosition);
    localName = name.substr(colonPosition + 1);
  }

  if ((this || _global).tagName === "INPUT" && name === "type") {
    (this || _global).type = value;
  } else {
    var attributes = (this || _global)._attributes[namespace] || ((this || _global)._attributes[namespace] = {});
    attributes[localName] = {
      value: value,
      prefix: prefix
    };
  }
};

DOMElement.prototype.getAttributeNS = function _Element_getAttributeNS(namespace, name) {
  var attributes = (this || _global)._attributes[namespace];
  var value = attributes && attributes[name] && attributes[name].value;

  if ((this || _global).tagName === "INPUT" && name === "type") {
    return (this || _global).type;
  }

  if (typeof value !== "string") {
    return null;
  }

  return value;
};

DOMElement.prototype.removeAttributeNS = function _Element_removeAttributeNS(namespace, name) {
  var attributes = (this || _global)._attributes[namespace];

  if (attributes) {
    delete attributes[name];
  }
};

DOMElement.prototype.hasAttributeNS = function _Element_hasAttributeNS(namespace, name) {
  var attributes = (this || _global)._attributes[namespace];
  return !!attributes && name in attributes;
};

DOMElement.prototype.setAttribute = function _Element_setAttribute(name, value) {
  return this.setAttributeNS(null, name, value);
};

DOMElement.prototype.getAttribute = function _Element_getAttribute(name) {
  return this.getAttributeNS(null, name);
};

DOMElement.prototype.removeAttribute = function _Element_removeAttribute(name) {
  return this.removeAttributeNS(null, name);
};

DOMElement.prototype.hasAttribute = function _Element_hasAttribute(name) {
  return this.hasAttributeNS(null, name);
};

DOMElement.prototype.removeEventListener = removeEventListener;
DOMElement.prototype.addEventListener = addEventListener;
DOMElement.prototype.dispatchEvent = dispatchEvent; // Un-implemented

DOMElement.prototype.focus = function _Element_focus() {
  return void 0;
};

DOMElement.prototype.toString = function _Element_toString() {
  return serializeNode(this || _global);
};

DOMElement.prototype.getElementsByClassName = function _Element_getElementsByClassName(classNames) {
  var classes = classNames.split(" ");
  var elems = [];
  domWalk(this || _global, function (node) {
    if (node.nodeType === 1) {
      var nodeClassName = node.className || "";
      var nodeClasses = nodeClassName.split(" ");

      if (classes.every(function (item) {
        return nodeClasses.indexOf(item) !== -1;
      })) {
        elems.push(node);
      }
    }
  });
  return elems;
};

DOMElement.prototype.getElementsByTagName = function _Element_getElementsByTagName(tagName) {
  tagName = tagName.toLowerCase();
  var elems = [];
  domWalk((this || _global).childNodes, function (node) {
    if (node.nodeType === 1 && (tagName === "*" || node.tagName.toLowerCase() === tagName)) {
      elems.push(node);
    }
  });
  return elems;
};

DOMElement.prototype.contains = function _Element_contains(element) {
  return domWalk(this || _global, function (node) {
    return element === node;
  }) || false;
};

export default exports;