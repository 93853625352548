import _domWalk from "dom-walk";
import _domComment from "./dom-comment.js";
import _domText from "./dom-text.js";
import _domElement from "./dom-element.js";
import _domFragment from "./dom-fragment.js";
import _event from "./event.js";
import _dispatchEvent from "./event/dispatch-event.js";
import _addEventListener from "./event/add-event-listener.js";
import _removeEventListener from "./event/remove-event-listener.js";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var domWalk = _domWalk;
var Comment = _domComment;
var DOMText = _domText;
var DOMElement = _domElement;
var DocumentFragment = _domFragment;
var Event = _event;
var dispatchEvent = _dispatchEvent;
var addEventListener = _addEventListener;
var removeEventListener = _removeEventListener;
exports = Document;

function Document() {
  if (!((this || _global) instanceof Document)) {
    return new Document();
  }

  (this || _global).head = this.createElement("head");
  (this || _global).body = this.createElement("body");
  (this || _global).documentElement = this.createElement("html");

  (this || _global).documentElement.appendChild((this || _global).head);

  (this || _global).documentElement.appendChild((this || _global).body);

  (this || _global).childNodes = [(this || _global).documentElement];
  (this || _global).nodeType = 9;
}

var proto = Document.prototype;

proto.createTextNode = function createTextNode(value) {
  return new DOMText(value, this || _global);
};

proto.createElementNS = function createElementNS(namespace, tagName) {
  var ns = namespace === null ? null : String(namespace);
  return new DOMElement(tagName, this || _global, ns);
};

proto.createElement = function createElement(tagName) {
  return new DOMElement(tagName, this || _global);
};

proto.createDocumentFragment = function createDocumentFragment() {
  return new DocumentFragment(this || _global);
};

proto.createEvent = function createEvent(family) {
  return new Event(family);
};

proto.createComment = function createComment(data) {
  return new Comment(data, this || _global);
};

proto.getElementById = function getElementById(id) {
  id = String(id);
  var result = domWalk((this || _global).childNodes, function (node) {
    if (String(node.id) === id) {
      return node;
    }
  });
  return result || null;
};

proto.getElementsByClassName = DOMElement.prototype.getElementsByClassName;
proto.getElementsByTagName = DOMElement.prototype.getElementsByTagName;
proto.contains = DOMElement.prototype.contains;
proto.removeEventListener = removeEventListener;
proto.addEventListener = addEventListener;
proto.dispatchEvent = dispatchEvent;
export default exports;