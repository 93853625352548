var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = dispatchEvent;

function dispatchEvent(ev) {
  var elem = this || _global;
  var type = ev.type;

  if (!ev.target) {
    ev.target = elem;
  }

  if (!elem.listeners) {
    elem.listeners = {};
  }

  var listeners = elem.listeners[type];

  if (listeners) {
    return listeners.forEach(function (listener) {
      ev.currentTarget = elem;

      if (typeof listener === "function") {
        listener(ev);
      } else {
        listener.handleEvent(ev);
      }
    });
  }

  if (elem.parentNode) {
    elem.parentNode.dispatchEvent(ev);
  }
}

export default exports;