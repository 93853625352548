var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = removeEventListener;

function removeEventListener(type, listener) {
  var elem = this || _global;

  if (!elem.listeners) {
    return;
  }

  if (!elem.listeners[type]) {
    return;
  }

  var list = elem.listeners[type];
  var index = list.indexOf(listener);

  if (index !== -1) {
    list.splice(index, 1);
  }
}

export default exports;